import React from "react";
import Hero from "../components/Hero";
import Onsale from "../components/Onsale";
import Contact from "../components/Contact";
import Kanagawa from "../components/Kanagawa";
import Movie from "../components/Movie";

import "./../css/index.css";

const Top = () => {
  return (
    <>
      <Hero />
      {/* <div className="kari">
        <div class="text-container">
          <p class="large-text">最上質の不動産とサービスの提供をめざします</p>
          <p class="medium-text">
            ヴィラリゾートは、最上質な不動産・サービスを感謝の心を込めて提供し
            <br />
            「人生を豊かにする」クリエイティブで新しいライフスタイルを創ります
          </p>
          <p class="small-text">
            沖縄で新しいライフスタイルを実現する価値あるラグジュアリー物件を中心に最上質な不動産をお客様に提供いたします。
          </p>
        </div>
      </div> */}
      <Kanagawa />
      <Onsale />
      <Movie />
      <Contact />
    </>
  );
};

export default Top;
