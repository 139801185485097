import React from "react";

import "./../css/Aboutus.css";

import test from "./../images/img_mv_pc.jpg";

const Contact = () => {
  return (
    <>
      <section id="contact" class="scrollspy-section padding-xlarge ">
        <div class="container">
          <div class="row">
            <div class="col-md-6">
              <div class="testimonial-block">
                <div class="section-header">
                  <div class="title">
                    <span>お問い合わせ</span>
                  </div>
                  <h2 class="section-title">Contact</h2>
                </div>

                <div class="col-md-12 description text-lead text-left">
                  <h4>お気軽にお問い合わせください。</h4>
                  <p className="text">
                    不動産の買取、仲介、企画設計、からコンサルティングまで、
                    <br />
                    全てのお客様を笑顔にする事業活動を行っております。
                  </p>
                  <a
                    href="http://www.baelzcorporation.jp/"
                    class="btn btn-accent btn-full btn-rounded "
                  >
                    本社Webサイトへ
                  </a>
                </div>
              </div>
            </div>

            <div class="col-md-6">
              <figure class="testimonial-image">
                <img src={test} alt="post" class="about-image" />
                {/* <img src={test} alt="post" class="post-image" /> */}
              </figure>
            </div>
          </div>
        </div>
      </section>

      {/* <section id="about" class="scrollspy-section padding-xlarge ">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <div class="section-header">
                <div class="title">
                  <span>私たちについて</span>
                </div>
                <h2 class="section-title">About Us</h2>
              </div>
            </div>
          </div>

          <div class="col-md-12 description text-lead text-left">
            <p>
              私たちベルツコーポレーションは、
              <br />
              横浜・湘南エリアで海沿いのロケーションを生かした
              <br />
              幅広い開発を行ってまいりました。
              立地を最大限にイノベーションする、
              <br />
              それが私たちベルツコーポレーションの目指すところです。
            </p>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="services-item">
                <span class="number">神奈川</span>
                <h3>KANAGAWA</h3>
                <p>
                  鎌倉、葉山、逗子、湘南エリアで海が見える土地、富士山を望む土地などを売買・賃貸と幅広く取り扱っております。
                </p>
              </div>
            </div>

            <div class="col-md-6">
              <div class="services-item">
                <span class="number">沖縄</span>
                <h3>OKINAWA</h3>
                <p>
                  美しい海を日常の中心に感じられる恵まれた土地で、スーパーラグジュアリーヴィラの開発を行い、
                  建物の設計に留まらず、リゾート用宅地としての景観創りから手がけております。
                </p>
              </div>
            </div>
          </div>
        </div>
      </section> */}
    </>
  );
};

export default Contact;
