import { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Thumbs } from "swiper/modules";
import { FaYenSign } from "react-icons/fa";

import "./../css/Property.css";
import "swiper/css";
import "swiper/css/navigation";

import "swiper/css/navigation";
import "swiper/css";
import "./../css/Hamamoto.css";

import hamamoto1 from "./../images/hamamoto1.jpg";
import hamamoto2 from "./../images/hamamoto2.jpg";
import hamamoto3 from "./../images/hamamoto3.jpg";
import hamamoto4 from "./../images/hamamoto4.jpg";
import hamamoto5 from "./../images/hamamoto5.jpg";
import video from "./../images/hamamoto_movie.mp4";

const Hamamoto = () => {
  const [thumbs, setThumbs] = useState(null);
  const images = [hamamoto1, hamamoto2, hamamoto3, hamamoto4, hamamoto5];

  return (
    <>
      <section id="on-sale" class="scrollspy-section padding-large">
        <div class="container">
          {/* <div class="row">
            <div class="col">
              <div class="section-header">
                <div class="title">
                  <span>販売中物件</span>
                </div>
                <h2 class="section-title">On sale</h2>
              </div>
            </div>
          </div> */}
          {/* 物件情報ここから1 */}
          <div class="row">
            <div class="col-md-12">
              <div class="post-grid">
                <div class="row">
                  {/* <div class="col-md-3"> */}
                  <article class="post-item">
                    {/* <figure> */}
                    {/* <a href="#" class="image-hvr-effect"> */}
                    {/* <img src={umusa} alt="post" class="post-image" /> */}
                    {/* </a> */}
                    {/* </figure> */}
                    <div class="post-content">
                      <div class="meta-date">
                        <div className="chips">販売中土地</div>
                      </div>
                      <h3 class="post-title">
                        {/* <a href="#"> */}
                        本部町浜元
                        観光地へのアクセス抜群　ヴィラ・別荘に最適なオーシャンフロント　美ら海水族館まで車5分
                        {/* </a> */}
                      </h3>

                      {/* スワイパー */}
                      {/* <Swiper
                        navigation={true}
                        modules={[Navigation]}
                        className="mySwiper"
                      >
                        <SwiperSlide className="swiper-sliede">
                          <img
                            src={umusa1}
                            alt="banner"
                            className="img-slide"
                          />
                        </SwiperSlide>
                        <SwiperSlide className="swiper-sliede">
                          <img
                            src={umusa2}
                            alt="banner"
                            className="img-slide"
                          />
                        </SwiperSlide>
                      </Swiper> */}

                      {/* Swiper2 */}
                      <Swiper
                        className="swiper-hamamoto"
                        loop={true}
                        modules={[Navigation, Thumbs]}
                        thumbs={{
                          swiper: thumbs && !thumbs.destroyed ? thumbs : null,
                        }}
                        navigation
                      >
                        {images.map((src, idx) => (
                          <SwiperSlide key={idx}>
                            <img src={src} className="img-slide3" />
                          </SwiperSlide>
                        ))}
                      </Swiper>
                      <Swiper
                        className="swiper-hamamoto Swiper1"
                        slidesPerView={5}
                        onSwiper={setThumbs}
                      >
                        {images.map((src, idx) => (
                          <SwiperSlide key={idx}>
                            <img src={src} />
                          </SwiperSlide>
                        ))}
                      </Swiper>

                      {/* <div class="meta-date">
                        <div className="chips">販売中土地</div>
                      </div> */}
                      <div class="tag-box">
                        <div class="box">
                          <div class="centered-text">別荘</div>
                        </div>
                        <div class="box">
                          <div class="centered-text">ヴィラ</div>
                        </div>
                        <div class="box">
                          <div class="centered-text">オーシャンビュー</div>
                        </div>
                        <div class="box">
                          <div class="centered-text">オーシャンフロント</div>
                        </div>
                      </div>

                      <div className="comment-box">
                        <p className="">
                          {/* 庭からそのまま海へアプローチ！ジェットスキーも釣りもご自分の”庭”からできます！
                          名護の繁華街までも近く、周辺の施設も充実しております。 */}
                        </p>
                      </div>

                      <div className="price-box">
                        <div className="price-title">
                          <p>価格</p>
                        </div>
                        <div className="box-text">
                          <p>金額についてはご相談ください。</p>
                        </div>
                        <div />
                      </div>

                      <div className="all-box">
                        <div className="one-box">
                          <div className="box-title">
                            <p>所在地</p>
                          </div>
                          <div className="box-text">
                            <p>沖縄県国頭郡本部町字浜元浜元原</p>
                          </div>
                        </div>
                        <div className="one-box">
                          <div className="box-title">
                            <p>交通</p>
                          </div>
                          <div className="box-text">
                            <p>那覇空港から約1時間30分</p>
                          </div>
                        </div>
                        <div className="one-box">
                          <div className="box-title">
                            <p>土地権利</p>
                          </div>
                          <div className="box-text">
                            <p>所有権</p>
                          </div>
                        </div>
                        <div className="one-box">
                          <div className="box-title">
                            <p>土地面積</p>
                          </div>
                          <div className="box-text">
                            <p>
                              A 191.81㎡(58.02) B 171,78㎡(51.96坪) C
                              138.00㎡(41坪)
                            </p>
                          </div>
                        </div>
                        <div className="one-box">
                          <div className="box-title">
                            <p>地目</p>
                          </div>
                          <div className="box-text">
                            <p>-</p>
                          </div>
                        </div>
                        <div className="one-box">
                          <div className="box-title">
                            <p>用途地域</p>
                          </div>
                          <div className="box-text">
                            <p>無指定</p>
                          </div>
                        </div>
                        <div className="one-box">
                          <div className="box-title">
                            <p>建蔽率</p>
                          </div>
                          <div className="box-text">
                            <p>60%</p>
                          </div>
                        </div>
                        <div className="one-box">
                          <div className="box-title">
                            <p>容積率</p>
                          </div>
                          <div className="box-text">
                            <p>200%</p>
                          </div>
                        </div>
                        <div className="one-box">
                          <div className="box-title">
                            <p>接道</p>
                          </div>
                          <div className="box-text">
                            <p>-</p>
                          </div>
                        </div>
                        <div className="one-box">
                          <div className="box-title">
                            <p>設備</p>
                          </div>
                          <div className="box-text">
                            <p>-</p>
                          </div>
                        </div>
                        <div className="one-box">
                          <div className="box-title">
                            <p>現況</p>
                          </div>
                          <div className="box-text">
                            <p>更地</p>
                          </div>
                        </div>
                        <div className="one-box">
                          <div className="box-title">
                            <p>取引態様</p>
                          </div>
                          <div className="box-text">
                            <p>売主</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </article>
                  <video
                    src={video}
                    muted
                    autoPlay
                    playsInline
                    loop
                    className="showcase-video"
                  ></video>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Hamamoto;
