import React from "react";

import banner from "./../images/banner.jpg";
import video from "./../images/hamamoto_movie.mp4";

const Movie = () => {
  return (
    <>
      <section id="" class="scrollspy-section padding-large">
        <div class="container">
          <div class="row">
            <div class="col">
              <div class="section-header">
                <div class="title">
                  <span>紹介動画</span>
                </div>
                <h2 class="section-title">Movie</h2>
              </div>
            </div>
          </div>
          <div class="col-md-12 description text-lead text-left">
            <h4>取扱い物件を動画でご覧ください。</h4>

            {/* <div className="image-container">
          <a href="/secretpage">
            <img className="image-contents" src={banner} alt="可変画像" />
          </a>
        </div> */}
            <video
              src={video}
              muted
              autoPlay
              playsInline
              loop
              className="showcase-video"
            ></video>
          </div>
        </div>
      </section>
    </>
  );
};

export default Movie;
