import React from "react";
import { FaYenSign } from "react-icons/fa";

import "./../css/Onsale.css";

import umusa1 from "./../images/umusaseiyaku1.jpg";
import hamamoto1 from "./../images/hamamoto1.jpg";
import ryugujyomae1 from "./../images/ryugujyomae1.jpg";
import yamakwagsyoko1 from "./../images/yamakwagsyoko1.jpg";
import nanmamui1 from "./../images/nanmamui1.jpg";
import yaga1 from "./../images/yaga1.jpg";

const Onsale = () => {
  return (
    <>
      <section id="on-sale" class="scrollspy-section padding-large">
        <div class="container">
          <div class="row">
            <div class="col">
              <div class="section-header">
                <div class="title">
                  <span>販売中物件</span>
                </div>
                <h2 class="section-title">On sale</h2>
              </div>
            </div>
          </div>
          <div class="col-md-12 description text-lead text-left">
            <h4>沖縄の海と景観、最上クラスの物件を。</h4>
          </div>

          <div class="row">
            <div class="col-md-12">
              <div class="post-grid">
                <div class="row">
                  {/* 物件1 */}
                  <div class="col-md-4">
                    <article class="post-item">
                      <figure>
                        {/* <a href="#" class="image-hvr-effect"> */}
                        <img src={umusa1} alt="post" class="post-image" />
                        {/* </a> */}
                      </figure>
                      <div class="post-content">
                        <div class="meta-date">
                          <div className="chips">販売中土地</div>
                        </div>
                        <h4 class="post-title">
                          {/* <a href="#"> */}
                          名護市宇茂佐
                          目の前プライベートビーチ　内海の湾のような地形に美しく穏やかな海が波打つ　別荘・セカンドハウスに
                          {/* </a> */}
                        </h4>
                        <div>
                          <FaYenSign />
                          <span className="en-family">
                            <strong className="en-number">15,800</strong>万円
                          </span>
                        </div>
                        <div className="salespoint">
                          <p>
                            <strong>所在/</strong>沖縄県名護市字宇茂佐
                            <br />
                            <strong>敷地/</strong>293.88㎡(88.89坪)
                          </p>
                          <a
                            href="https://www.e-uchina.net/bukken/tochi/t-6341-6240103-0031/detail.html"
                            class="btn btn-accent btn-full btn-rounded"
                          >
                            他サイトで詳細を見る
                          </a>
                          <a
                            href="/umusa"
                            class="btn btn-accent btn-full btn-rounded"
                          >
                            詳細はこちら
                          </a>
                        </div>
                      </div>
                    </article>
                  </div>

                  {/* 物件2 */}
                  <div class="col-md-4">
                    <article class="post-item">
                      <figure>
                        {/* <a href="#" class="image-hvr-effect"> */}
                        <img src={hamamoto1} alt="post" class="post-image" />
                        {/* </a> */}
                      </figure>
                      <div class="post-content">
                        <div class="meta-date">
                          <div className="chips">販売中土地</div>
                        </div>
                        <h4 class="post-title">
                          {/* <a href="#"> */}
                          本部町浜元
                          観光地へのアクセス抜群　ヴィラ・別荘に最適なオーシャンフロント　美ら海水族館まで車5分
                          {/* </a> */}
                        </h4>
                        <div>
                          <span className="en-family">
                            価格/お問い合わせください。
                          </span>
                        </div>
                        <div className="salespoint">
                          <div>
                            <p>
                              <strong>所在/</strong>
                              沖縄県国頭郡本部町字浜元浜元原
                              <br />
                              <strong>敷地/</strong>191.81㎡(58.02坪)
                            </p>
                            <a
                              href="/hamamoto"
                              class="btn btn-accent btn-full btn-rounded"
                            >
                              詳細はこちら
                            </a>
                          </div>
                        </div>
                      </div>
                    </article>
                  </div>

                  {/* 物件3 */}
                  <div class="col-md-4">
                    <article class="post-item">
                      <figure>
                        {/* <a href="#" class="image-hvr-effect"> */}
                        <img src={ryugujyomae1} alt="post" class="post-image" />
                        {/* </a> */}
                      </figure>
                      <div class="post-content">
                        <div class="meta-date">
                          <div className="chips">販売中土地</div>
                        </div>
                        <h4 class="post-title">
                          {/* <a href="#"> */}
                          本部町山川　美ら海水族館へと続くメインストリートの道沿い　高台パノラマオーシャンビュー　収益ホテル・リゾートヴィラ　
                          {/* </a> */}
                        </h4>
                        <div>
                          <span className="en-family">
                            価格/お問い合わせください。
                          </span>
                        </div>
                        <div className="salespoint">
                          <div>
                            <p>
                              <strong>所在/</strong>
                              沖縄県国頭郡字山川西原
                              <br />
                              <strong>敷地/</strong>5,259.08㎡(1,590.87坪)
                            </p>
                            <a
                              href="/ryuguzyo"
                              class="btn btn-accent btn-full btn-rounded"
                            >
                              詳細はこちら
                            </a>
                          </div>
                        </div>
                      </div>
                    </article>
                  </div>

                  {/* 物件4 */}
                  <div class="col-md-4">
                    <article class="post-item">
                      <figure>
                        {/* <a href="#" class="image-hvr-effect"> */}
                        <img
                          src={yamakwagsyoko1}
                          alt="post"
                          class="post-image"
                        />
                        {/* </a> */}
                      </figure>
                      <div class="post-content">
                        <div class="meta-date">
                          <div className="chips">販売中土地</div>
                        </div>
                        <h4 class="post-title">
                          {/* <a href="#"> */}
                          本部町山川　美ら海水族館までのメインストリート沿い　向かって左手に位置　面積5000㎡超
                          {/* </a> */}
                        </h4>
                        <div>
                          <span className="en-family">
                            価格/お問い合わせください。
                          </span>
                        </div>
                        <div className="salespoint">
                          <div>
                            <p>
                              <strong>所在/</strong>
                              沖縄県国頭郡本部町山川
                              <br />
                              <strong>敷地/</strong>5,325.83㎡(1,617.11坪)
                            </p>
                            <a
                              href="/yamakawags"
                              class="btn btn-accent btn-full btn-rounded"
                            >
                              詳細はこちら
                            </a>
                          </div>
                        </div>
                      </div>
                    </article>
                  </div>

                  {/* 物件5 */}
                  <div class="col-md-4">
                    <article class="post-item">
                      <figure>
                        {/* <a href="#" class="image-hvr-effect"> */}
                        <img src={nanmamui1} alt="post" class="post-image" />
                        {/* </a> */}
                      </figure>
                      <div class="post-content">
                        <div class="meta-date">
                          <div className="chips">販売中土地</div>
                        </div>
                        <h4 class="post-title">
                          {/* <a href="#"> */}
                          饒平名湧増　屋我地内海一望　リゾートホテル・戸建てヴィラ・収益物件
                          {/* </a> */}
                        </h4>
                        <div>
                          <span className="en-family">
                            価格/お問い合わせください。
                          </span>
                        </div>
                        <div className="salespoint">
                          <div>
                            <p>
                              <strong>所在/</strong>
                              沖縄県名護市字饒平名湧増
                              <br />
                              <strong>敷地/</strong>24,739㎡(7,483.54坪)
                            </p>
                            <a
                              href="/nanmamui"
                              class="btn btn-accent btn-full btn-rounded"
                            >
                              詳細はこちら
                            </a>
                          </div>
                        </div>
                      </div>
                    </article>
                  </div>

                  {/* 物件6 */}
                  <div class="col-md-4">
                    <article class="post-item">
                      <figure>
                        {/* <a href="#" class="image-hvr-effect"> */}
                        <img src={yaga1} alt="post" class="post-image" />
                        {/* </a> */}
                      </figure>
                      <div class="post-content">
                        <div class="meta-date">
                          <div className="chips">販売中土地</div>
                        </div>
                        <h4 class="post-title">
                          {/* <a href="#"> */}
                          名護市屋我ビーチフロント
                          自然溢れるオーシャンフロントの広大な一画　最上級リゾート立地
                          {/* </a> */}
                        </h4>
                        <div>
                          <span className="en-family">
                            価格/お問い合わせください。
                          </span>
                        </div>
                        <div className="salespoint">
                          <div>
                            <p>
                              <strong>所在/</strong>
                              沖縄県名護市屋我
                              <br />
                              <strong>敷地/</strong>18,740㎡(5,668.85坪)
                            </p>
                            <a
                              href="/yaga"
                              class="btn btn-accent btn-full btn-rounded"
                            >
                              詳細はこちら
                            </a>
                          </div>
                        </div>
                      </div>
                    </article>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Onsale;
